import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Accordion, Modal } from "react-bootstrap";
import {
  clearAllSound,
  removeSound,
  removeAudio,
  emptySoundRemoval,
  removeMusic,
  clearAllMixer,
  removeMix,
} from "../../Store/Slices/SoundPlayerSlice";

// import { playAll } from "../../Store/Slices/SoundPlayerSlice";

import {
  // MixerButton,
  // TimerButton,
  PauseButton,
  PlayButton,
  // Spinner,
  CrossIcon,
  crossIcon,
  MyMixes,
  // playIcon,
  playButton,
  MixerIcon,
  pauseButton,
  saveMixButton,
  timerButton,
  ShareIcon,
  SaveMix,
  HeartIcon,
  MixerGreen,

  // mixerIcon,
  // timerIcon,
  // saveMixIcon,
} from "../../Assets/svg";
import BASEURL from "../../Config/global";

import { Howl, Howler } from "howler";
import CustomButton from "../CustomButton";
import { getAccessToken } from "../../Util/authHeader";
import { useLocation, useNavigate } from "react-router";
import {
  pauseMixer,
  playMixer,
  resetMixer,
} from "../../Store/Slices/MixerSlice";
import VolumeBar from "../VolumeBar";
import VolumeSlider from "./../VolumeSlider";
import SoundsAccordian from "../SoundsAccordian";
import ShareMixSave from "../ShareMixSave/ShareMixSave";
import axios from "axios";
import { isIOS, isMobileSafari, isSafari } from "react-device-detect";
import { AudioDuration, AudioDurationClose, AudioDurationStop } from "../../Store/Slices/AudioSlice";
import { ClearMixer, ClearExternalMixData, PauseMyMixer, PlayMyMixer, handleMixerTray } from "../../Store/Slices/MyMixerSlice";
import { setShowPaywallModal } from "../../Store/Slices/UserSlice";

// import { MixerIcon } from "../../Assets/images";

const MultiAudioPlayerrr = () => {
  const Navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const menuClass = windowWidth < 1024 ? "mobileMenu" : "desktopMenu";
  const mixerTrayView = windowWidth < 575 ? true : false;
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const location = useLocation();
  const inputRef = useRef("Default Mixer");

  const sounds = useSelector((state) => state.soundPlayer.sounds);
  const music = useSelector((state) => state.soundPlayer.music);
  const soundRemoval = useSelector((state) => state.soundPlayer.soundRemoval);
  const audio = useSelector((state) => state.soundPlayer.audio);
  const isPlaying = useSelector((state) => state.mixer.play);
  const audioSeekTime = useSelector((state) => state.audio.seekTime);
  const PlayMixer = useSelector((state) => state.mymixer.isMixerPlay);
  const PauseMixer = useSelector((state) => state.mymixer.isMixerPause);
  const MyMixRedux = useSelector((state) => state.soundPlayer.isMixes);
  const MyMixSounds = useSelector((state) => state.soundPlayer.mix);
  const myMixObj = useSelector((state) => state.soundPlayer.mixObj);
  const checkElegibility = useSelector((state) => state.user.checkElegibility);

  

  const _externalMixList = useSelector((state) => state.mymixer.externalMixList);
  const _isMixerTrayOpen = useSelector((state) => state.mymixer.isMixerTrayOpen);


  const dispatch = useDispatch();

  const [soundList, setSoundList] = useState([]);
  const [audioState, setAudioState] = useState({});
  const [musicState, setMusicState] = useState({});
  const [sourceList, setSourceList] = useState([]);
  const [lastSource, setLastSource] = useState(null);
  const [soundInfo, setSoundInfo] = useState(false);
  const [audioInfo, setAudioInfo] = useState(false);
  const [shareListShowOnly, setShareListShowOnly] = useState([]);
  const [howlList, setHowlList] = useState([]);
  const [audioHowl, setAudioHowl] = useState(null);
  const [musicHowl, setMusicHowl] = useState(null);
  const [howlCount, setHowlCount] = useState(0);
  const [mixerTimer, setMixerTimer] = useState(null);

  const [overallVolume, setOverallVolume] = useState(0.5);
  const [showTimerModal, setShowTimerModal] = useState(false);

  const [loader, setLoader] = useState([]);
  const [openTray, setOpenTray] = useState(false);

  const [isShareMixSaveShow, setIsShareMixSaveShow] = useState(false);
  const [saveAndShare, setSaveAndShare] = useState(false);
  const [isShareMixSaveId, setIsShareMixSaveId] = useState(0);
  const [isShareMixSaveName, setIsShareMixSaveName] = useState("");

  const [saveMixModal, setSaveMixModal] = useState(false);
  const [succesModal, setSuccesModal] = useState(false);
  const [listner, setlistner] = useState(false);
  const [saveMixError, setSaveMixError] = useState(false);
  const [saveMixErrorMsg, setSaveMixErrorMsg] = useState({ error: false, msg: '' });



  const [mixList, setMixList] = useState([]);
  const [mixListHowl, setMixListHowl] = useState([]);

  const [isShareIndividualSaveShow, setIsShareIndividualSaveShow] = useState(false);
  const [saveShareMixId, setSaveShareMixId] = useState(0);
  const [shareSoundlist, setShareSoundlist] = useState([]);
  const [shareAudio, setShareAudio] = useState({});
  const [shareIndividualUrl, setShareIndividualUrl] = useState()

  useEffect(() => {
    if (_externalMixList.length > 0) {
      setOpenTray(true);
      handlePauseAll();
    }
  }, [_externalMixList]);

  useEffect(() => {
    setOpenTray(_isMixerTrayOpen);
    if (mixerTrayView && _isMixerTrayOpen) {
      document.body.classList.add('overflow-hidden');
      document.body.classList.add('mixer-tray-opened');
    }else{
      document.body.classList.remove('overflow-hidden');
      document.body.classList.remove('mixer-tray-opened');
    }
  }, [_isMixerTrayOpen]);

  useEffect(() => {
    const token = getAccessToken();
    if (!token) {
      handleClearMix();
    }
  }, [location]);

  useEffect(() => {
    if (MyMixSounds.length > 0) {
      if (mixListHowl.length > 0) {
        mixListHowl.forEach((howl) => {
          howl.unload();
        });
        setMixList([]);
        setMixListHowl([]);
      }
      const array = []
      MyMixSounds.forEach(item => {
        let baseUrl = '';
        if ((item?.sounds_detail != null && item?.sounds_detail.length > 0 && item?.sounds_detail[0]?.audio.startsWith('/media/assets'))) {
          baseUrl = 'https://storage.googleapis.com/relax_dev/assets' + item?.sounds_detail[0]?.audio.split('/media/assets')[1]
        } else if ((item?.sounds_detail != null && item?.sounds_detail.length > 0)) {
          baseUrl = item?.sounds_detail[0]?.audio
        }

        const howl = new Howl({
          src: [(item?.sounds_detail != null && item?.sounds_detail.length > 0) && baseUrl],
          loop: (item?.sounds_detail != null && item?.sounds_detail.length > 0) && item?.sounds_detail[0].type === "sounds" ? true : false,
          autoplay: true,
          webAudio: true, // Use Web Audio API if supported
          html5: true, // Use HTML5 audio if supported
          autoUnlock: true,
          preload: true,
          volume: 1,
          autoSuspend: false,
          onload: function () {
            console.log("loaded");
          },
        });
        array.push(howl)
      });
      setMixList(MyMixSounds);
      setMixListHowl(array);
      // dispatch(playMixer())
    }
    clearMyMixerSound(MyMixSounds);

  }, [MyMixSounds])

  useEffect(() => {
    if (mixListHowl.length > 0) {
      dispatch(playMixer())
    }
  }, [mixListHowl])

  // useEffect(() => {
  //   if (MyMixSounds.length > 0 && sounds.length > 0 || audio?.audio || music?.audio) {
  //     handleClearMyMix();
  //     handleClearMyMixDispatch();
  //     dispatch(playMixer());
  //   }
  //   if (!isPlaying && sounds.length > 0 || audio?.audio || music?.audio) {
  //     dispatch(playMixer());
  //   }
  //   console.log(sounds, audio, music)
  // }, [sounds, audio, music])

  useEffect(() => {
    if (mixListHowl.length > 0 && (sounds.length > 0 || audio?.audio || music?.audio)) {
      // debugger
      handleClearMyMix();
      handleClearMyMixDispatch();
      setTimeout(() => {
        dispatch(playMixer());
      }, 1000);
    }
  }, [howlList, audioHowl, musicHowl])

  const clearMyMixerSound = (data) => {
    if (data.length > 0) {
      dispatch(clearAllSound());
      if (howlList.length > 0) {
        howlList.forEach((howl) => {
          howl.unload();
        });
        setHowlList([]);
        // setIsPlaying(false);
      }
      if (audioState?.audio) {
        audioHowl.unload();
        setAudioHowl(null);
        setAudioState(null);
      }
      if (musicState?.audio) {
        musicHowl.unload();
        setMusicHowl(null);
        setMusicState(null);
      }
    }
  }

  useEffect(() => {
    const duplicateArray = [];
    sounds.forEach((sound) => {
      duplicateArray.push(sound);
    });
    setSoundList(duplicateArray);
  }, [sounds]);

  useEffect(() => {
    if (!MyMixRedux) {
      const lastElem = soundList[soundList.length - 1];
      setLastSource(lastElem?.audio);
      setSoundInfo(lastElem);
    }
  }, [soundList]);

  useEffect(() => {
    if (!MyMixRedux) {
      setAudioState(audio);
    }
  }, [audio]);

  useEffect(() => {
    if (!MyMixRedux) {
      setMusicState(music);
    }
  }, [music]);

  // useEffect(() => {
  //   const lastElem = sourceList[sourceList.length - 1];
  //   setLastSource(lastElem);

  //   const lastSoundInfo = soundList[soundList.length - 1];
  //   setSoundInfo(lastSoundInfo);
  // }, [sourceList]);

  // useEffect(() => {
  //   // console.log(soundList[soundList.length - 1].naration);
  //   if (howlList.length) {
  //     howlList.filter((eachHowl, index) => {
  //       if (eachHowl.info.naration) {
  //         handleRemoveSound(index);
  //       }
  //     });
  //   }
  // }, [soundList]);

  useEffect(() => {
    if (!MyMixRedux) {
      if (soundList.length > 0 && soundList.length > howlCount) {
        const howl = new Howl({
          src: [lastSource],
          loop: true,
          autoplay: isPlaying,
          webAudio: true, // Use Web Audio API if supported
          html5: true, // Use HTML5 audio if supported
          autoUnlock: true,
          preload: true,
          volume: 1,
          autoSuspend: false,
          onload: function () {
            this.loaded = true;
            setLoader((prevLoader) => [...prevLoader, true]);
          },
        });
        howl.loaded = false;
        howl.info = soundInfo;
        const duplicateHowlList = [...howlList];
        duplicateHowlList.push(howl);
        setHowlList(duplicateHowlList);
      }
      setHowlCount(howlList.length);
    }
  }, [lastSource]);

  useEffect(() => {
    if (audioHowl) {
      // If an existing audioHowl instance exists, unload it
      audioHowl.unload();
    }
    if (!MyMixRedux) {
      const howl = new Howl({
        src: [audioState?.audio],
        loop: false,
        autoplay: isPlaying,
        webAudio: true, // Use Web Audio API if supported
        html5: true, // Use HTML5 audio if supported
        autoUnlock: true,
        preload: true,
        volume: 1,
        autoSuspend: false,
        onload: function () {
          this.loaded = true;
          setLoader((prevLoader) => [...prevLoader, true]);
          dispatch(AudioDuration());
        },
        onend: function () {
          dispatch(removeAudio());
        }
      });
      setAudioHowl(howl);
    }
  }, [audioState]);

  useEffect(() => {
    if (musicHowl) {
      // If an existing audioHowl instance exists, unload it
      musicHowl.unload();
    }
    if (!MyMixRedux) {
      const howl = new Howl({
        src: [musicState?.audio],
        loop: true,
        autoplay: isPlaying,
        webAudio: true, // Use Web Audio API if supported
        html5: true, // Use HTML5 audio if supported
        autoUnlock: true,
        preload: true,
        volume: 1,
        autoSuspend: false,
        onload: function () {
          this.loaded = true;
        },
        onload: function () {
          this.loaded = true;
          setLoader((prevLoader) => [...prevLoader, true]);
        },
      });
      setMusicHowl(howl);
    }
  }, [musicState]);

  if (audioSeekTime) {
    setTimeout(() => {
      audioHowl.seek(audioSeekTime);
    }, 100);
  }

  // useEffect(() => {
  //   if (PauseMixer) {
  //     handlePauseAll();
  //   }
  // }, [PauseMixer])

  // useEffect(() => {
  //   if (PlayMixer) {
  //     handlePlayAll();
  //   }
  // }, [PlayMixer])

  const handlePauseAll = () => {
    if (audioHowl) {
      audioHowl.pause();
    }
    if (musicHowl) {
      musicHowl.pause();
    }
    if (howlList.length > 0) {
      howlList.forEach((howl) => {
        howl.pause();
      });
    }
    if (mixListHowl.length > 0) {
      mixListHowl.forEach((howl) => {
        howl.pause();
      });
    }
    dispatch(pauseMixer());
  };

  const handlePlayAll = () => {
    if (audioHowl) {
      audioHowl.play();
    }
    if (musicHowl) {
      musicHowl.play();
    }
    if (howlList.length > 0) {
      howlList.forEach((howl) => {
        howl.play();
      });
    }
    if (mixListHowl.length > 0) {
      mixListHowl.forEach((howl) => {
        howl.play();
      });
    }
    if (mixListHowl.length > 0 || howlList.length > 0 || musicHowl || audioHowl) {
      dispatch(playMixer());
      dispatch(PlayMyMixer());
    }
  };

  const handlePlayAllAction = () => {
    dispatch(playMixer());
    dispatch(PlayMyMixer());
    // handlePlayAll();
  }

  const handlePauseAllAction = () => {
    dispatch(pauseMixer());
    // handlePauseAll();
  }

  useEffect(() => {
    if (isPlaying) {
      handlePlayAll()
    }
    else {
      handlePauseAll()
    }
  }, [isPlaying])

  useEffect(() => {
    if (MyMixRedux) {
      if (howlList.length > 0) {
        howlList.forEach((howl) => {
          howl.unload();
        });
        setHowlList([]);
      }
      if (audioState.audio) {
        audioHowl.unload();
        setAudioHowl(null);
        setAudioState(null);
      }
      if (musicState.audio) {
        musicHowl.unload();
        setMusicHowl(null);
        setMusicState(null);
      }
    }
  }, [MyMixRedux])

  const handleIndividualVolume = (e, index) => {
    const volumeLevel = parseFloat(e.target.value);
    howlList[index].volume(volumeLevel);
  };

  const handleSoundVolume = (method, index) => {
    if (method === "Increase") {
      const newVolume = Math.min(howlList[index]?.volume() + 0.1, 1.0);
      howlList[index].volume(newVolume);
      console.log("increase", newVolume.toFixed(1));
    } else if (method === "Decrease") {
      const newVolume = Math.max(howlList[index]?.volume() - 0.1, 0);
      howlList[index].volume(newVolume);
      console.log("decrease", newVolume.toFixed(1));
    }
  };

  // const handleRemoveSound = (item, index) => {
  //   howlList[index].unload();

  //   const updatedHowlList = [...howlList];
  //   updatedHowlList.splice(index, 1);
  //   setHowlList(updatedHowlList);

  //   const updatedSoundList = [...soundList];

  //   updatedSoundList.splice(index, 1);
  //   setSoundList(updatedSoundList);

  //   dispatch(removeSound(item));


  //   if (updatedSoundList.length === 0) {
  //     document.body.classList.remove('overflow-hidden');
  //   }
  // };


  const handleRemoveSound = (item) => {
    dispatch(removeSound(item))
  }

  useEffect(() => {
    if (soundRemoval != null) {
      howlList.forEach((item, idx) => {
        if (item.info.id == soundRemoval.id) {
          howlList[idx].unload();
          const updatedHowlList = [...howlList];
          updatedHowlList.splice(idx, 1);
          setHowlList(updatedHowlList);

          const updatedSoundList = [...soundList];
          updatedSoundList.splice(idx, 1);
          setSoundList(updatedSoundList);
          dispatch(emptySoundRemoval())
        }

      })
    }
  }, [soundRemoval])

  const handleRemoveAudio = (index) => {
    // howlList[index].unload();

    // const updatedHowlList = [...howlList];
    // updatedHowlList.splice(index, 1);
    // setHowlList(updatedHowlList);

    // const updatedSoundList = [...soundList];
    // updatedSoundList.splice(index, 1);
    // setSoundList(updatedSoundList);
    if (audioState.audio) {
      audioHowl.unload();
      setAudioHowl(null);
      setAudioState(null);
      dispatch(removeAudio());
    }
    // dispatch(removeSound(index));

    if (soundList.length === 0 && !audioState.hasOwnProperty(audio) && !musicState.hasOwnProperty(audio)) {
      if (mixerTrayView) {
        document.body.classList.remove('overflow-hidden');
        document.body.classList.remove('mixer-tray-opened');
      }
    }

  };

  const handleRemoveMusic = (index) => {
    if (musicState.audio) {
      musicHowl.unload();
      setMusicHowl(null);
      setMusicState(null);
      dispatch(removeMusic());
    }
    // dispatch(removeSound(index));

    if (soundList.length === 0 && !audioState.hasOwnProperty(audio) && !musicState.hasOwnProperty(audio)) {
      if (mixerTrayView) {
        document.body.classList.remove('overflow-hidden');
        document.body.classList.remove('mixer-tray-opened');
      }
    }

  };

  const handleClearMyMix = () => {
    if (mixListHowl.length > 0) {
      mixListHowl.forEach((howl) => {
        howl.unload();
      });
      setMixList([]);
      setMixListHowl([]);
    }
    if (mixerTrayView) {
      document.body.classList.remove('overflow-hidden');
      document.body.classList.remove('mixer-tray-opened');
    }
    // Handle Mixer Tray
    dispatch(handleMixerTray(false))
  }

  const handleClearMyMixDispatch = () => {
    dispatch(removeMix());
    dispatch(pauseMixer());
  }

  const handleClearMix = () => {
    console.log("Clear My Mix -----")
    if (mixerTrayView) {
      document.body.classList.remove('overflow-hidden');
      document.body.classList.remove('mixer-tray-opened');
    }
    setOpenTray(false)
    if (howlList.length > 0) {
      howlList.forEach((howl) => {
        howl.unload();
      });
      setHowlList([]);
      dispatch(clearAllSound());
      dispatch(resetMixer());
      // setIsPlaying(false);
    }
    if (audioState?.audio) {
      audioHowl.unload();
      setAudioHowl(null);
      setAudioState(null);
      dispatch(clearAllSound());
      dispatch(resetMixer());
    }
    if (musicState?.audio) {
      musicHowl.unload();
      setMusicHowl(null);
      setMusicState(null);
      dispatch(clearAllSound());
      dispatch(resetMixer());
    }
    handleClearMyMix();
    handleClearMyMixDispatch();
    controlledClearAll();
    // Handle Mixer Tray
    dispatch(handleMixerTray(false))
    // if (MyMixRedux) {
    //   dispatch(clearAllSound());
    //   dispatch(clearAllMixer());
    //   dispatch(pauseMixer());
    // }
  };

  const runTimer = (minutes) => {
    console.log(minutes);
    if (minutes === null) {
      clearTimeout(mixerTimer);
      return;
    } else {
      const milliSeconds = minutes * 60 * 1000;
      clearTimeout(mixerTimer);
      const timeOut = setTimeout(() => {
        handleClearMix();
      }, milliSeconds);
      setMixerTimer(timeOut);
    }
    handleCloseTimer();
  };

  const handleCloseTimer = () => setShowTimerModal(false);
  const handleShowTimer = () => setShowTimerModal(true);


  const closeShareMix = () => {
    setIsShareMixSaveShow(false)
    setShareListShowOnly([])
    setSaveAndShare(false)
  }

  const closeAllPanels = () => {
    setIsShareMixSaveShow(false);
    setOpenTray(false);
    let random = Math.random();
    Navigate(`/shares.mix/${random}`)
    handleClearMix()
  }

  const shareMixSaveTrayOpen = () => {//saud checks
    inputRef.current = "00990066";
    createShareMix();
    setIsShareMixSaveShow(true);
  }

  const saveShareMix = async (share) => {
    createShareMix(share);
  }

  const createShareMix = (share) => {
    let saveMixerName = "";
    if (inputRef.current.value) {
      saveMixerName = inputRef.current.value.replace(/\//g, '-');
    } else {
      setSaveMixError(true);
    }
    const postData = [];

    if (soundList && soundList.length > 0) {
      soundList.forEach((items, index) => {
        postData.push({
          "audio": (isMobileSafari || isSafari || isIOS) ? items.ios_patch : items.audio,
          "tumbnail": items.thumbnail,
          "image": items.image,
          "name": items.title,
          "sound_id": items.id,
          "type": "sounds",
          "narrate": items.naration
        })
      });
    }
    if (audioState.audio) {
      postData.push({
        "audio": audioState.audio,
        "tumbnail": audioState.thumbnail,
        "image": audioState.image,
        "name": audioState.title,
        "sound_id": audioState.id,
        "type": audioState.type,
        "narrate": audioState.naration
      })
    }
    if (musicState.audio) {
      postData.push({
        "audio": musicState.audio,
        "tumbnail": musicState.thumbnail,
        "image": musicState.image,
        "name": musicState.title,
        "sound_id": musicState.id,
        "type": "sounds",
        "narrate": musicState.naration
      })
    }

    if (MyMixSounds && MyMixSounds.length > 0) {
      MyMixSounds.forEach((items, index) => {
        postData.push({
          "audio": (isMobileSafari || isSafari || isIOS) ? (items['sounds_detail'][0].type === "sounds") ? items['sounds_detail'][0].ios_patch : items['sounds_detail'][0].audio : items['sounds_detail'][0].audio,
          "tumbnail": items['sounds_detail'][0].thumbnail,
          "image": items['sounds_detail'][0].image,
          "name": items['sounds_detail'][0].title,
          "sound_id": items['sounds_detail'][0].id,
          "type": items['sounds_detail'][0].type,
          "narrate": items['sounds_detail'][0].naration
        })
      });
    }

    if (postData.length > 0 && saveMixerName != "") {
      const fetchData = async () => {
        try {
          setSaveMixErrorMsg({ error: false, msg: '' })
          const mixerPostData = {
            "name": saveMixerName,
            "sounds_list": postData
          };
          const response = await axios.post(`${BASEURL}/api/sounds/sounds_mixture`, mixerPostData);
          if (!response.data.error && response.data.data) {
            localStorage.setItem("saveMix", JSON.stringify({
              "mixerId": response.data.data.id,
              "mixerName": saveMixerName,
              "mixerIdExist": true,
            }));
            // setIsShareMixSaveId(response.data.data.id);
            setSaveMixModal(false);
            setSuccesModal(true);
            setSaveMixError(false);
            setTimeout(() => {
              setSuccesModal(false);
              if (share === "share") {
                let checkMix = JSON.parse(localStorage.getItem("saveMix"));
                if (checkMix != null) {
                  if (checkMix?.mixerIdExist) {
                    setIsShareMixSaveId(checkMix.mixerId);
                    setIsShareMixSaveName(checkMix.mixerName);
                    setIsShareMixSaveShow(true);
                    setOpenTray(false);
                  }
                }
                if (mixerTrayView) {
                  document.body.classList.remove('overflow-hidden');
                  document.body.classList.remove('mixer-tray-opened');
                }
              }
            }, 1000);
            if (saveAndShare) {
              setTimeout(() => {
                setIsShareMixSaveId(response.data.data.id);
                setIsShareMixSaveShow(true);
              }, 1500)
            }

          } else {
            setSaveMixErrorMsg({ error: true, msg: 'An unexpected error occurred. Please try again later.' })
          }
        } catch (error) {
          inputRef.current.value = "";
          setSaveMixErrorMsg({ error: true, msg: 'An unexpected error occurred. Please try again later.' })
          setIsShareMixSaveShow(false);
          console.error(error);
        }
      }
      fetchData();
    }
  }

  const currentSounds = useSelector((state) => state.soundPlayer.currentSoundsList);

  const openMixerTray = () => {
    // console.log(currentSounds)
  }

  const clickListen = () => {
    setlistner(!listner)
  }

  const succesModalShow = (share) => {
    setSaveAndShare(false)
    setShareListShowOnly([])
    saveShareMix(share);
  }

  const checkShouldSaveModal = () => {
    let postData = false;

    if (soundList && soundList.length > 0) {
      postData = true
    }
    if (audioState.audio) {
      postData = true
    }
    if (musicState.audio) {
      postData = true
    }
    if (_externalMixList && _externalMixList.length > 0) {
      postData = true
    }
    return postData
  }

  const shareMix = (showShareModal) => {
    if (!checkElegibility) {
      dispatch(setShowPaywallModal(true))
      // handleClearMix()
      return
    }

    let checkMix = JSON.parse(localStorage.getItem("saveMix"));
    if (checkMix != null) {
      if (checkMix?.mixerIdExist) {
        setIsShareMixSaveId(checkMix?.mixerId);
        setIsShareMixSaveName(checkMix?.mixerName);
        setIsShareMixSaveShow(true)
      }
    } else {
      if (myMixObj?.id && showShareModal) {
        setIsShareMixSaveId(myMixObj?.id);
        setIsShareMixSaveName(myMixObj?.name);
        setShareListShowOnly(myMixObj?.sounds_list)
        setIsShareMixSaveShow(true)
      } else {
        if (showShareModal) {
          setSaveAndShare(true)
        }
        setSaveMixModal(true)
      }
    }
  }
  const handleSaveMixModal = () => {
    if (!checkElegibility) {
      dispatch(setShowPaywallModal(true))
      // handleClearMix()
      return
    }
    setSaveMixModal(true)
  }

  // console.log(!mixerTrayView && !location.pathname.includes('/share.mix'))
  const saveShareMixList = (type, sound) => {
    setSaveShareMixId(sound.id);
    setIsShareMixSaveName(sound.name ? sound.name : sound.title);
    if (type === "music" || type === "sound") {
      if (type === "sound") {
        setShareSoundlist([sound])
      } else {
        setShareAudio(sound)
      }
      setShareIndividualUrl('share/audio-detail/sounds')
    }
    else {
      setShareAudio(sound)
      setShareSoundlist([])
      setShareIndividualUrl(`share/audio-detail/${type}`)
    }
    setIsShareIndividualSaveShow(true);
    document.body.classList.add('overflow-hidden');
  }


  const controlledClearAll = () => {
    dispatch(ClearExternalMixData());
    dispatch(pauseMixer());
    setOpenTray(false);
  }


  return (
    <>
      {/* Howl List */}
      {(sounds.length > 0 || audio?.audio || music?.audio || MyMixSounds.length > 0) ? (
        <div className={`audioPlayerWrapper ${menuClass}`}>

          {/* Audio Player */}
          <div className="row">
            <div className="col-12">
              <div className="d-flex">
                <div className="audioPlayerControls playing-audio-player-controls audio-player-controls-active">
                  {isPlaying ? (
                    <button
                      className="playButton playerAction active pause-btn"
                      onClick={handlePauseAllAction}
                    >
                      <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Media / Pause">
                          <g id="Vector">
                            <path d="M15 5.5V18.5C15 18.9647 15 19.197 15.0384 19.3902C15.1962 20.1836 15.816 20.8041 16.6094 20.9619C16.8026 21.0003 17.0349 21.0003 17.4996 21.0003C17.9642 21.0003 18.1974 21.0003 18.3906 20.9619C19.184 20.8041 19.8041 20.1836 19.9619 19.3902C20 19.1987 20 18.9687 20 18.5122V5.48777C20 5.03125 20 4.80087 19.9619 4.60938C19.8041 3.81599 19.1836 3.19624 18.3902 3.03843C18.197 3 17.9647 3 17.5 3C17.0353 3 16.8026 3 16.6094 3.03843C15.816 3.19624 15.1962 3.81599 15.0384 4.60938C15 4.80257 15 5.03534 15 5.5Z" stroke="#f7ba31" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4 5.5V18.5C4 18.9647 4 19.197 4.03843 19.3902C4.19624 20.1836 4.81599 20.8041 5.60938 20.9619C5.80257 21.0003 6.0349 21.0003 6.49956 21.0003C6.96421 21.0003 7.19743 21.0003 7.39062 20.9619C8.18401 20.8041 8.8041 20.1836 8.96191 19.3902C9 19.1987 9 18.9687 9 18.5122V5.48777C9 5.03125 9 4.80087 8.96191 4.60938C8.8041 3.81599 8.18356 3.19624 7.39018 3.03843C7.19698 3 6.96465 3 6.5 3C6.03535 3 5.80257 3 5.60938 3.03843C4.81599 3.19624 4.19624 3.81599 4.03843 4.60938C4 4.80257 4 5.03534 4 5.5Z" stroke="#f7ba31" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                          </g>
                        </g>
                      </svg>
                      <p className="playerActionText mt-1" style={{ fontSize: "10px", fontWeight: "300", width: "32px" }}>Pause</p>
                    </button>
                  ) : (
                    <button
                      className="playButton playerAction active play-btn"
                      onClick={handlePlayAllAction}
                    >
                      <svg width="25px" height="25px" id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.57 65.13">
                        <g id="Layer_1-2">
                          <path fill="none" stroke="#25e544" strokeWidth={'5px'} strokeMiterlimit={10} d="m47.18,36.15L9.26,61.88c-2.87,1.95-6.76-.11-6.76-3.58V6.83c0-3.47,3.88-5.53,6.76-3.58l37.92,25.73c2.53,1.72,2.53,5.44,0,7.16Z" />
                        </g>
                      </svg>
                      <p className="playerActionText mt-1" style={{ fontSize: "10px", fontWeight: "300", width: "32px" }}>Play</p>
                    </button>
                  )}
                  {/* <div className="mixerCenter d-lg-block d-none flex-grow-1">
                  <p className="overallVolumeText">Volume</p>
                  <VolumeSlider />
                </div> */}
                  {/* <div className="mixerRight"> */}
                  <button className="playerAction">
                    <img
                      src={SaveMix}
                      alt="Save"
                      className="playerActionIcon"
                      onClick={handleSaveMixModal}
                    />
                    <p className="playerActionText mt-1" style={{ fontSize: "10px", fontWeight: "300" }}>Save Mix</p>
                  </button>
                  <button className="playerAction">
                    <img
                      src={MyMixes}
                      style={{ filter: 'unset' }}
                      alt="saved mix"
                      className="playerActionIcon"
                      onClick={() => Navigate("/save-mixers")}
                    />
                    <p className="playerActionText mt-1" style={{ fontSize: "10px", fontWeight: "300" }}>My Mixes</p>
                  </button>
                  <button className="playerAction" onClick={handleShowTimer}>
                    {/* <img
                      src={timerButton}
                      alt="Timer"
                      className="playerActionIcon"
                      onClick={handleShowTimer}
                    /> */}
                    <svg width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.36 25.36">
                      <g id="Layer_2" data-name="Layer 2">
                        <g id="Sounds"><circle class="cls-1" fill="#fff" cx="12.68" cy="12.68" r="12.18" />
                          <path class="cls-2" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" d="M20.33,16.19l-6.17-3.27a1.94,1.94,0,0,0,0-.24,1.48,1.48,0,0,0-.84-1.34L12.57,3.1l-.78,8.38a1.5,1.5,0,0,0,.89,2.7,1.47,1.47,0,0,0,.92-.32Z" />
                        </g>
                      </g>
                    </svg>
                    <p className="playerActionText mt-1" style={{ fontSize: "10px", fontWeight: "300", width: "32px" }}>Timer</p>
                  </button>
                  <button
                    className="playerAction active"
                    onClick={() => {
                      if (openTray) {
                        if (mixerTrayView) {
                          document.body.classList.remove('overflow-hidden');
                          document.body.classList.remove('mixer-tray-opened');
                        }
                      } else {
                        if (mixerTrayView) {
                          document.body.classList.add('overflow-hidden');
                          document.body.classList.add('mixer-tray-opened');
                        }
                      }
                      setOpenTray(!openTray);
                      clickListen();
                      openMixerTray()
                    }}
                  >
                    <svg id="Layer_2" width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65.13 65.13">
                      <g id="Layer_1-2">
                        <line fill="none" stroke="#25e544" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={'5px'} x1="2.5" y1="62.63" x2="2.5" y2="14.89" />
                        <line fill="none" stroke="#25e544" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={'5px'} x1="17.53" y1="62.63" x2="17.53" y2="29.76" />
                        <line fill="none" stroke="#25e544" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={'5px'} x1="32.57" y1="62.63" x2="32.57" y2="2.5" />
                        <line fill="none" stroke="#25e544" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={'5px'} x1="47.6" y1="62.63" x2="47.6" y2="19.85" />
                        <line fill="none" stroke="#25e544" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={'5px'} x1="62.63" y1="62.63" x2="62.63" y2="39.67" />
                      </g>
                    </svg>
                    <p className="playerActionText mt-1" style={{ fontSize: "10px", fontWeight: "300", width: "32px" }}>Mixer</p>
                  </button>
                  {/* <div
                      className="currentMixButton flex-column align-items-center"
                      onClick={() => {
                        openTray ? document.body.classList.remove('overflow-hidden') : document.body.classList.add('overflow-hidden');
                        setOpenTray(!openTray);
                        clickListen();
                        openMixerTray()
                      }}
                    >
                      <div className="currentMixIcons">
                        <div className="currentImageWrapper bg-transparent">
                          <img
                            src={MixerIcon}
                            alt="Thumbnail"
                          />
                        </div>
                      </div>
                      <p className="playerActionText mt-1 mb-0" style={{ fontSize: "10px", fontWeight: "300" }}>Mixer</p>
                    </div> */}
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* Audio Player End */}

          {/* Mixer Tray */}
          {
            !mixerTrayView && <div className={`d-flex flex-column mixerTray ${openTray ? "open" : "close"}`}>

              <div className="mixerHeader flex-shrink-0 p-3 py-4">
                <h3>Mixer</h3>
                <div className="text-end d-flex align-items-center gap-2">
                  <button onClick={() => shareMix()} className="notButton text-capitalize text-white" style={{ fontSize: "14px" }}>
                    Save
                  </button>
                  |
                  <button onClick={() => shareMix(true)} className="text-capitalize notButton  text-white" style={{ fontSize: "14px" }}>
                    share
                  </button>
                  <button
                    className="notButton d-flex align-items-center ms-1"
                    onClick={() => {
                      if (mixerTrayView) {
                        document.body.classList.remove('overflow-hidden');
                        document.body.classList.remove('mixer-tray-opened');
                      }
                      setOpenTray(false);
                      dispatch(handleMixerTray(false))
                    }}
                  >
                    <img src={crossIcon} alt="Close Button" className="" />
                  </button>
                </div>
              </div>

              <div className="mixer-scroll-wrapper flex-grow-1 overflow-auto">

                {audio?.audio && (
                  <div className="audio-wrapper flex-shrink-0">
                    <div className="audioHeader px-3 pb-2 d-flex justify-content-between">
                      <p>{audio.type == 'articles' ? 'Audio Articles' : audio.type}</p>
                      <button className="clearMixButton" onClick={handleClearMix}>
                        Clear All
                      </button>
                    </div>
                    <div className="otherAudioWrapper">
                      <div className="individualAudio px-3">
                        <div className="mixerSoundDetail">
                          <div className="mixerSoundThumbnailWrapper flex-shrink-0">
                            <div className="mixerAudioThumbnail">
                              <img
                                src={audio.image}
                                alt="Thumbnail"
                              />
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <p className="mixerAudioTitle">
                              {audio.title}
                            </p>
                          </div>
                          <div className="d-flex align-center gap-2 flex-shrink-0">
                            {/* <span className='soundControlButton' onClick={() => saveShareMixList(audio.type, audio)}>
                              <img src={ShareIcon} alt="sound-and-music-share" />
                            </span> */}
                            <span
                              className="closeButton"
                              onClick={handleRemoveAudio}
                            >
                              <img src={crossIcon} alt="Cross Icon" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {(soundList.length > 0 || musicState?.audio) && (
                  <div className="sounds-wrapper flex-shrink-0">
                    <div className="audioHeader px-3 pb-2 d-flex justify-content-between">
                      <p>Sounds & Music</p>
                      {
                        !audioState?.audio && <button className="clearMixButton" onClick={handleClearMix}>Clear All</button>
                      }
                    </div>
                    <div className="individualSoundsWrapper pb-2">
                      {soundList.length > 0 && soundList.map((sound, index) => (
                        <div className="individualAudio px-3 py-2" key={index}>
                          <div className="mixerSoundDetail">
                            <div className="mixerSoundThumbnailWrapper flex-shrink-0">

                              <div className="mixerSoundThumbnail-2">
                                <img
                                  src={sound.image}
                                  alt="Thumbnail"
                                />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="mixerAudioTitle">
                                {sound.title}
                              </p>
                            </div>
                            <div className="d-flex align-center gap-2 flex-shrink-0 flex-row">
                              {/* <span className='soundControlButton' onClick={() => saveShareMixList("sounds",sound)}>
                                <img src={ShareIcon} alt="sound-and-music-share" />
                              </span> */}
                              <span
                                className="closeButton"
                                onClick={() => {
                                  handleRemoveSound(sound);
                                }}
                              >
                                <img src={crossIcon} alt="Cross Icon" />
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                      {
                        musicState?.audio && (
                          <div className="individualAudio px-3 py-2">
                            <div className="mixerSoundDetail">
                              <div className="mixerSoundThumbnailWrapper flex-shrink-0">

                                <div className="mixerSoundThumbnail-2">
                                  <img
                                    src={musicState.image}
                                    alt="Thumbnail"
                                  />
                                </div>
                              </div>
                              <div className="flex-grow-1">
                                <p className="mixerAudioTitle">
                                  {musicState.title}
                                </p>
                              </div>
                              <div className="d-flex align-center gap-2 flex-shrink-0">
                                {/* <span className='soundControlButton' onClick={() => saveShareMixList("music", musicState)}>
                                <img src={ShareIcon} alt="sound-and-music-share" />
                              </span> */}
                                <span
                                  className="closeButton"
                                  onClick={handleRemoveMusic}
                                >
                                  <img src={crossIcon} alt="Cross Icon" />
                                </span>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                )}

                {(MyMixSounds.length > 0) && (
                  <div className="sounds-wrapper flex-shrink-0">
                    <div className="audioHeader px-3 pb-2 d-flex justify-content-between">
                      <p>My Mix</p>
                      {
                        (soundList.length === 0 && !audio?.audio && !musicState?.audio) && <button className="clearMixButton" onClick={() => { handleClearMyMix(); handleClearMyMixDispatch(); controlledClearAll(); }}>Clear All</button>
                      }
                    </div>
                    <div className="individualSoundsWrapper pb-2">
                      {MyMixSounds.length > 0 && MyMixSounds.map((sound, index) => (
                        <div className="individualAudio px-3 py-2" key={index}>
                          <div className="mixerSoundDetail">
                            <div className="mixerSoundThumbnailWrapper flex-shrink-0">

                              <div className="mixerSoundThumbnail-2">
                                <img
                                  src={(sound?.sounds_detail != null && sound?.sounds_detail.length > 0) && sound?.sounds_detail[0].image}
                                  alt="Thumbnail"
                                />
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="mixerAudioTitle">
                                {(sound?.sounds_detail != null && sound?.sounds_detail.length > 0) && sound?.sounds_detail[0].title}
                              </p>
                            </div>
                            <div className="d-flex align-center gap-2 flex-shrink-0 d-none">
                              <span
                                className="closeButton"
                              >
                                <img src={crossIcon} alt="Cross Icon" />
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* <div className="sounds-category-wrapper" style={{ borderBottom: "none" }}>
                  <div className="">
                    <Accordion
                      alwaysOpen
                      className="customAccordion"
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="mixer-accordian-header">Add Sounds</Accordion.Header>
                        <Accordion.Body className="px-2 mixer-accordian-body">
                          <SoundsAccordian list="Multi Player" listner={listner} currentSounds={currentSounds} />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div> */}
              </div>

              {/* <div className="clearMixWrapper flex-shrink-0">
                <button className="clearMixButton" onClick={handleClearMix}>
                  Clear All
                </button>
              </div> */}

            </div>
          }
          {/* Mixer Tray End */}

        </div>
      ) : (!mixerTrayView && !location.pathname.includes('/share.mix') && !location.pathname.includes('/404') && !location.pathname.includes('/qr-code')) &&
      (
        <div className={`audioPlayerWrapper ${menuClass}`}>
          <div className="row">
            <div className="col-12">
              <div className="d-flex">
                <div className="audioPlayerControls">
                  <button
                    className="playButton playerAction play-pause-mixer"
                  >
                    <img
                      src={playButton}
                      alt="Pause"
                      className="playerActionIcon"
                    />
                    <p className="playerActionText mt-1" style={{ fontSize: "10px", fontWeight: "300" }}>Play</p>
                  </button>
                  <div className="mixerRight">
                    <button className="playerAction">
                      <img
                        src={SaveMix}
                        alt="Save"
                        className="playerActionIcon"
                      />
                      <p className="playerActionText mt-1" style={{ fontSize: "10px", fontWeight: "300" }}>Save Mix</p>
                    </button>
                    <button className="playerAction">
                      <img
                        src={MyMixes}
                        style={{ filter: 'unset' }}
                        alt="saved mix"
                        className="playerActionIcon"
                        onClick={() => Navigate("/save-mixers")}
                      />
                      <p className="playerActionText mt-1" style={{ fontSize: "10px", fontWeight: "300" }}>My Mixes</p>
                    </button>
                    <button className="playerAction">
                      <svg width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.36 25.36">
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Sounds"><circle class="cls-1" fill="#fff" cx="12.68" cy="12.68" r="12.18" />
                            <path class="cls-2" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" d="M20.33,16.19l-6.17-3.27a1.94,1.94,0,0,0,0-.24,1.48,1.48,0,0,0-.84-1.34L12.57,3.1l-.78,8.38a1.5,1.5,0,0,0,.89,2.7,1.47,1.47,0,0,0,.92-.32Z" />
                          </g>
                        </g>
                      </svg>
                      <p className="playerActionText mt-1" style={{ fontSize: "10px", fontWeight: "300", width: "32px" }}>Timer</p>

                    </button>
                    <button
                      className="playerAction"
                    >
                      <img
                        src={MixerIcon}
                        alt="Thumbnail"
                      />
                      <p className="playerActionText mt-1" style={{ fontSize: "10px", fontWeight: "300" }}>Mixer</p>
                    </button>
                    {/* <div
                      className="currentMixButton flex-column align-items-center"
                    >
                      <div className="currentMixIcons">
                        <div className="currentImageWrapper bg-transparent">
                          <img
                            src={MixerIcon}
                            alt="Thumbnail"
                          />
                        </div>
                      </div>
                      <p className="playerActionText mt-1 mb-0" style={{ fontSize: "10px", fontWeight: "300" }}>Mixer</p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      )}
      {/* Howl List End */}


      {/* Timer Modal */}
      <Modal
        show={showTimerModal}
        centered
        onHide={handleCloseTimer}
        className="soundModal"
        backdrop="static"
      >
        <Modal.Body>
          <div className="text-end">
            <button
              className="closeButton notButton ms-auto"
              onClick={handleCloseTimer}
            >
              {/* <CrossIcon /> */}
              <img src={crossIcon} alt="" />
            </button>
          </div>
          <div className="customModalContent text-center">
            <h2 className="modalHeading pageTitle mb-4">Select Time</h2>
            <div className="timerOptions">
              {/* <CustomButton
                variant="secondaryButton fw-light"
                text="None"
                onClick={() => {
                  runTimer(null);
                }}
              /> */}
              {/* <CustomButton
                variant="secondaryButton fw-light"
                text="Testing only"
                onClick={() => {
                  runTimer(1);
                }}
              /> */}
              <CustomButton
                variant="secondaryButton fw-light"
                text="15 Minutes"
                onClick={() => {
                  runTimer(15);
                }}
              />
              <CustomButton
                variant="secondaryButton fw-light"
                text="30 Minutes"
                onClick={() => {
                  runTimer(30);
                }}
              />
              <CustomButton
                variant="secondaryButton fw-light"
                text="1 Hour"
                onClick={() => {
                  runTimer(60);
                }}
              />
              <CustomButton
                variant="secondaryButton fw-light"
                text="2 Hours"
                onClick={() => {
                  runTimer(120);
                }}
              />
              <CustomButton
                variant="secondaryButton fw-light"
                text="4 Hours"
                onClick={() => {
                  runTimer(240);
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Timer Modal End */}


      {/* Mobile Mixer Tray */}
      {
        mixerTrayView && <div className={`d-flex flex-column mixerTray ${openTray ? "open" : "close"}`}>

          <div className="mixerHeader flex-shrink-0 p-3 py-4">
            <h3>Mixer</h3>
            <div className="text-end d-flex align-items-center gap-2">
              <button onClick={() => shareMix()} className="notButton text-capitalize text-white" style={{ fontSize: "14px" }}>
                Save
              </button>
              |
              <button onClick={() => shareMix(true)} className="text-capitalize notButton  text-white" style={{ fontSize: "14px" }}>
                share
              </button>
              <button
                className="notButton d-flex align-items-center ms-1"
                onClick={() => {
                  if (mixerTrayView) {
                    document.body.classList.remove('overflow-hidden');
                    document.body.classList.remove('mixer-tray-opened');
                  }
                  setOpenTray(false);
                  clickListen();
                  dispatch(handleMixerTray(false))
                }}
              >
                <img src={crossIcon} alt="Close Button" className="" />
              </button>
            </div>
          </div>

          <div className="mixer-scroll-wrapper flex-grow-1 overflow-auto">

            {audio?.audio && (
              <div className="audio-wrapper flex-shrink-0">
                <div className="audioHeader px-3 pb-2 d-flex justify-content-between">
                  <p>{audio.type == 'articles' ? 'Audio Articles' : audio.type}</p>
                  <button className="clearMixButton" onClick={handleClearMix}>Clear All</button>
                </div>
                <div className="otherAudioWrapper">
                  <div className="individualAudio px-3">
                    <div className="mixerSoundDetail">
                      <div className="mixerSoundThumbnailWrapper flex-shrink-0">
                        <div className="mixerAudioThumbnail">
                          <img
                            src={audio.image}
                            alt="Thumbnail"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <p className="mixerAudioTitle">
                          {audio.title}
                        </p>
                      </div>
                      <div className="d-flex align-center gap-2 flex-shrink-0">
                        {/* <span className='soundControlButton' onClick={() => saveShareMixList(audio.type, audio)}>
                          <img src={ShareIcon} alt="sound-and-music-share" />
                        </span> */}
                        <span
                          className="closeButton"
                          onClick={handleRemoveAudio}
                        >
                          <img src={crossIcon} alt="Cross Icon" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(soundList.length > 0 || musicState?.audio) && (
              <div className="sounds-wrapper flex-shrink-0">

                <div className="audioHeader px-3 pb-2 d-flex justify-content-between">
                  <p>Sounds & Music</p>
                  {
                    !audio?.audio && <button className="clearMixButton" onClick={handleClearMix}>Clear All</button>
                  }
                </div>

                <div className="individualSoundsWrapper pb-2">
                  {soundList.map((sound, index) => (
                    <div className="individualAudio px-3 py-2" key={index}>
                      <div className="mixerSoundDetail">
                        <div className="mixerSoundThumbnailWrapper flex-shrink-0">

                          <div className="mixerSoundThumbnail-2">
                            <img
                              src={sound.image}
                              alt="Thumbnail"
                            />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <p className="mixerAudioTitle">
                            {sound.title}
                          </p>
                        </div>
                        <div className="d-flex align-center gap-2 flex-shrink-0">
                          {/* <span className='soundControlButton' onClick={() => saveShareMixList("sound", sound)}>
                          <img src={ShareIcon} alt="sound-and-music-share" />
                        </span> */}
                          <span
                            className="closeButton"
                            onClick={() => {
                              handleRemoveSound(sound);
                            }}
                          >
                            <img src={crossIcon} alt="Cross Icon" />
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                  {
                    musicState?.audio && (
                      <div className="individualAudio px-3 py-2">
                        <div className="mixerSoundDetail">
                          <div className="mixerSoundThumbnailWrapper flex-shrink-0">

                            <div className="mixerSoundThumbnail-2">
                              <img
                                src={musicState.image}
                                alt="Thumbnail"
                              />
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <p className="mixerAudioTitle">
                              {musicState.title}
                            </p>
                          </div>
                          <div className="d-flex align-center gap-2 flex-shrink-0">
                            {/* <span className='soundControlButton' onClick={() => saveShareMixList("music", musicState)}>
                              <img src={ShareIcon} alt="sound-and-music-share" />
                            </span> */}
                            <span
                              className="closeButton"
                              onClick={handleRemoveMusic}
                            >
                              <img src={crossIcon} alt="Cross Icon" />
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            )}

            {(MyMixSounds.length > 0) && (
              <div className="sounds-wrapper flex-shrink-0">
                <div className="audioHeader px-3 pb-2 d-flex justify-content-between">
                  <p>My Mix</p>
                  {
                    (soundList.length === 0 && !audio?.audio && !musicState?.audio) && <button className="clearMixButton" onClick={() => { handleClearMyMix(); handleClearMyMixDispatch(); controlledClearAll(); }}>Clear All</button>
                  }
                </div>
                <div className="individualSoundsWrapper pb-2">
                  {MyMixSounds.length > 0 && MyMixSounds.map((sound, index) => (
                    <div className="individualAudio px-3 py-2" key={index}>
                      <div className="mixerSoundDetail">
                        <div className="mixerSoundThumbnailWrapper flex-shrink-0">

                          <div className="mixerSoundThumbnail-2">
                            <img
                              src={(sound?.sounds_detail != null && sound?.sounds_detail.length > 0) && sound?.sounds_detail[0].image}
                              alt="Thumbnail"
                            />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <p className="mixerAudioTitle">
                            {sound?.sounds_detail[0].title}
                          </p>
                        </div>
                        <div className="d-flex align-center gap-2 flex-shrink-0 d-none">
                          <span
                            className="closeButton"
                          >
                            <img src={crossIcon} alt="Cross Icon" />
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* <div className="sounds-category-wrapper">
              <div className="">
                <Accordion
                  alwaysOpen
                  className="customAccordion"
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="mixer-accordian-header" style={{ zIndex: 999 }}>Add Sounds</Accordion.Header>
                    <Accordion.Body className="px-2 mixer-accordian-body">
                      <SoundsAccordian list="Multi Player" listner={listner} currentSounds={currentSounds} />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div> */}

          </div>

          {/* <div className="clearMixWrapper flex-shrink-0 d-none" style={{ borderTop: "2px solid #ffffff38" }}>
            <button className="clearMixButton" onClick={handleClearMix}>
              Clear All
            </button>
          </div> */}

          <div className="mixer-footer d-sm-none flex-shrink-0">
            <div className="d-flex justify-content-between align-items-center px-5 w-100">
              {/* Timer */}
              <div className="flex-shrink-0">
                <button className="playerAction">
                  <img
                    src={timerButton}
                    alt="Timer"
                    className="playerActionIcon"
                    onClick={handleShowTimer}
                    style={{ height: '25px' }}
                  />
                  <p className="playerActionText mt-2">Add Timer</p>
                </button>
              </div>
              {/* play pause */}
              <div className="flex-shrink-0">
                {isPlaying ? (
                  <button
                    className="playButton playerAction white-circle"
                    onClick={handlePauseAllAction}
                  >

                    <img
                      src={pauseButton}
                      alt="Pause"
                      className="playerActionIcon"
                    />
                  </button>
                ) : (
                  <button
                    className="playButton playerAction white-circle"
                    onClick={handlePlayAllAction}
                  >
                    {/* <PlayButton className="playerActionIcon" /> */}
                    {/* <p className="playerActionText">Play</p> */}
                    <img
                      src={playButton}
                      alt="Play"
                      className="playerActionIcon"
                    />
                  </button>
                )}
              </div>
              {/* Save Mix */}
              <div className="flex-shrink-0">
                <button className="playerAction">
                  <img
                    src={SaveMix}
                    alt="Timer"
                    className="playerActionIcon"
                    onClick={handleSaveMixModal}
                    style={{ height: '25px' }}
                  />
                  <p className="playerActionText mt-2">Save Mix</p>
                </button>
              </div>
            </div>
          </div>


        </div>
      }
      {/* Mobile Mixer Tray End*/}

      <Modal
        show={saveMixModal}
        centered
        className="soundModal"
        backdrop="static"
      >
        <Modal.Body>
          <div className="text-end">
            <button
              className="closeButton notButton ms-auto"
              onClick={() => { setSaveMixModal(false); setSaveMixError(false); setSaveMixErrorMsg({ error: false, msg: '' }) }}
            >
              {/* <CrossIcon /> */}
              <img src={crossIcon} alt="" />
            </button>
          </div>
          <div className="customModalContent mt-5">
            <h2 className="fs-6 fw-normal mb-3 ps-3">Mix Name</h2>
            <div>
              <input type="search" ref={inputRef} className="searchBar w-100" />
              {saveMixError && <small className="text-danger">Please enter mix name</small>}
              {saveMixErrorMsg?.error && <small className="text-danger d-inline-block mt-2 text-center" style={{ lineHeight: 1.4 }}>{saveMixErrorMsg?.msg}</small>}
            </div>
            <div className="mt-4 d-flex gap-3 justify-content-center">
              <button style={{ backgroundColor: '#ffffff1f' }} className='px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0' onClick={succesModalShow}>
                <span className=''>Save</span>
              </button>
              {/* <button style={{ backgroundColor: '#ffffff1f' }} className='px-3 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0' onClick={() => succesModalShow("share")}>
                <span className=''>Save & Share</span>
              </button> */}
            </div>
            {/* <div className="mt-5 mb-3">
              <p className="m-0 opacity-75 fw-light text-center font-16 lh-16" style={{ fontSize: '14px' }}>Save & Share
                your mixes with friends
                and family</p>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={isShareMixSaveShow}
        centered
        className="mixer-modal"
        backdrop="static"
      >
        <Modal.Body>
          <ShareMixSave handleClick={closeShareMix} mixList={MyMixSounds} isSaveList={false} soundlist={soundList} audiolist={audioState} musicList={musicState} id={isShareMixSaveId} shareMixName={isShareMixSaveName} shareListShowOnly={shareListShowOnly} />
        </Modal.Body>
      </Modal>

      <Modal
        show={isShareIndividualSaveShow}
        centered
        className="mixer-modal"
        backdrop="static"
      >
        <Modal.Body>
          <ShareMixSave shareIndividualUrl={shareIndividualUrl} mixList={[]} handleClick={() => setIsShareIndividualSaveShow(false)} isSaveList={true} soundlist={shareSoundlist} audiolist={shareAudio} id={saveShareMixId} shareMixName={isShareMixSaveName} />
        </Modal.Body>
      </Modal>

      <Modal
        show={succesModal}
        centered
        className="success-modal"
        backdrop="static"
      >
        <Modal.Body>
          <div className="p-5">
            <h2 className="font-16 lh-16 my-5 fw-normal my-3 text-center">Mix Succesfully Saved</h2>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};
export default MultiAudioPlayerrr;
